.navbar-brand {
	padding: 5px 15px;

	img {
		height: 40px;
	}
}

.panel-fb {
	border-color: $fb-blue;

	.panel-heading {
		background-color: $fb-blue;
		color: #fff;
	}
}

.btn-fb {
	background-color: $fb-blue;
	border-color: $fb-blue;
	color: #fff;

	&:hover {
		background-color: $fb-light;
		border-color: $fb-light;
		color: #fff;
	}
}

.panel-twit {
	border-color: $twit-blue;

	.panel-heading {
		background-color: $twit-blue;
		color: #fff;
	}
}

.btn-twit {
	background-color: $twit-blue;
	border-color: $twit-blue;
	color: #fff;

	&:hover {
		background-color: $twit-dark;
		border-color: $twit-dark;
		color: #fff;
	}
}

.modal-footer {
	form {
		display: inline-block;
	}
}

.map {
	height: 0;
	padding-bottom: 40%;
	margin-bottom: 1rem;
	width: 100%;
}

.d-flex{
	display: flex;
}

.align-item-space-between{
	justify-content: space-between;
	align-items: center;
}

.mt-11{
	margin-top: 11px;
}

#notification-table{

 	.noti-ul{
		position: relative;
	
		div{
			padding-left: 15px;

			&.active{
				&:after {
					content: "";
					position: absolute;
					width: 10px;
					height: 10px;
					background: #32833e;
					top: 13px;
					bottom: 0;
					left: 6px;
					border-radius: 50px;
				}
			}

			&.not-active{
				&:after {
					content: "";
					position: absolute;
					width: 10px;
					height: 10px;
					background: #F1A61E;
					top: 13px;
					bottom: 0;
					left: 6px;
					border-radius: 50px;
				}
			}
		}
	}
}